.t-mission {
  background-color: #262e3c;
  padding: 48px 24px;
  position: relative;

  &__id {
    position: absolute;
    top: -75px;
  }

  &:before {
    content: 'Миссия';
    width: 81px;
    height: 19px;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1.6px;
    text-align: center;
    color: #f3f3f3;
    text-transform: uppercase;
  }

  &__text {
    position: relative;
    width: 50%;
    opacity: 0.8;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
    margin: 27px 0 42px;


    &:before {
      position: absolute;
      opacity: 0.15;
      font-family: 'Basis Regular', serif;;
      font-size: 140px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8e8295;
    }

    &:before {
      content: '“';
      top: -40px;
      left: -25px;
    }
  }

  &__sign {
    width: 50%;
    height: 19px;
    font-size: 16px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:before {
      content: 'Дарья Кучина, CEO';
    }
  }

  &__ceo {
    position: absolute;
    top: 35px;
    bottom: 0;
    right: 0;
    left: 50%;
    background-image: url("../_desktop/slides/mission/images/ceo@2x.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
  }

}
