$nav-links: mission, vacancies, contacts;
$menuHeight: 64px;
$bgc: #212732;
.m-base {
  background-color: $bgc;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &__content {
    position: relative;
  }

  &__header {
    background-image: url("../images/logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 103px;
    height: 22px;
  }
}


a {
  text-decoration: none;
}


.m-base__nav {
  height: $menuHeight;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  &_back{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bgc;
  }
}

.m-base__back {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: -1;

  &.--active {
    z-index: 10;
    opacity: 0.5;
  }
}


.nav-links {
  display: block;
  position: absolute;
  z-index: -1;
  top: 60px;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  transform: translate3d(0, -100%, 0);

  li {
    height: $menuHeight;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: all .3s;
    text-align: center;
    font-size: 16px;
    letter-spacing: 1.6px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    font-weight: bold;
    text-transform: uppercase;

    &:nth-child(1) {
      background-color: #262e3c;
    }

    &:nth-child(2) {
      background-color: #394250;
    }

    &:nth-child(3) {
      background-color: #4f5969;
    }

    a {
      color: #f3f3f3;
      display: block;
      width: 100%;
      height: $menuHeight;
      line-height: $menuHeight;

      &:hover {
        color: #ffffff;
      }
    }
  }
}

// Hidden checkbox to triger mobile nav
input[type="checkbox"] {
  display: none;

  &:checked + label {
    height: 28px;
    top: 16px;
    left: 18px;
    width: 28px;
    transform: rotate(-135deg);
    border: 0;

    &::before {
      top: 12px;
      left: -4px;
      width: 32px;
    }

    &::after {
      opacity: 1;
      top: 12px;
      left: -4px;
      visibility: visible;
      width: 32px;
    }

    &:hover {
      border-width: 0;

      &::before,
      &::after {
        background: #ffffff;
      }
    }
  }

  &:checked ~ .nav-links {
    transform: translate3d(0, 0, 0);

    &::before {
      opacity: 1;
      visibility: visible;
    }

    li {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);

      @for $i from 1 through length($nav-links) {
        &:nth-child(#{$i}) {
          transition-delay: ($i - 1)/10+s;
        }
      }
    }
  }
}

// Styling of the linked label element
label[for='m-menu'] {
  background: none transparent;
  border: 2px solid #ffffff;
  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  cursor: pointer;
  display: block;
  height: 18px;
  position: absolute;
  top: 23px;
  left: 20px;
  width: 30px;
  transition: transform .2s;
  z-index: 20;

  &:before, &:after {
    height: 2px;
  }

  &::before {
    background: #ffffff;
    content: '';
    left: 0;
    position: absolute;
    top: 6px;
    width: 30px;
    transition: all .2s;
  }

  &::after {
    background: #ffffff;
    content: '';
    left: 0;
    position: absolute;
    top: 6px;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    transform: rotate(90deg);
    transition: all .2s;
  }
}

.m-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}


