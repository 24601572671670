.m-mission {
  padding: 84px 8px 0;
  background-color: #262e3c;

  &__text {
    position: relative;

    &:before, &:after {
      display: block;
      font-style: normal;
      font-stretch: normal;
    }

    &:before {
      content: 'Миссия';
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 1.6px;
      color: #f3f3f3;
      margin-bottom: 15px;
      text-align: center;
    }

    ._text {
      display: block;
      max-width: 325px;
      min-height: 351px;
      opacity: 0.8;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: normal;
      color: #ffffff;
    }

    &:after {
      content: '“';
      top: -30px;
      left: -35px;
      position: absolute;
      opacity: 0.15;
      font-family: 'Basis Regular', serif;;
      font-size: 220px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8e8295;
    }
  }

  &__sign {
    margin: 42px 0 34px;
    padding-left: 8px;
    width: 315px;
    height: 19px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;

    &:before {
      content: 'Дарья Кучина, CEO';
    }
  }

  &__ceo {
    background-image: url("../_desktop/slides/mission/images/ceo@2x.jpg");
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 431px;
    height: 479px;
    background-size: contain;
  }
}
