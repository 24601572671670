.t-contacts {
  background-color: #4f5969;
  display: flex;
  min-height: 480px;
  position: relative;

  &__id {
    position: absolute;
    top: -75px;
  }

  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 25px;

    &:before {
      content: 'Контакты';
      text-transform: uppercase;
      display: block;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 1.6px;
      color: #f3f3f3;
    }
  }

  &__phone {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    margin: 20px 0 10px;
  }

  .t-hibasiscenter {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
  }

  &__address {
    opacity: 0.8;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
    margin: 15px 0;
    width: 325px;
  }

  &__accreditation {
    width: 360px;
    opacity: 0.8;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
  }

  &__how {
    display: flex;
    align-items: center;
    height: 19px;
    font-size: 16px;
    line-height: normal;
    letter-spacing: normal;
    color: #57ab6a;
    margin-bottom: 50px;

    &:hover {
      cursor: pointer;
    }

    &:before {
      content: 'Как проехать';
    }

    &:after {
      content: '';
      display: block;
      width: 13px;
      height: 13px;
      background-size: contain;
      background-image: url("../_mobile/images/arrow.svg");
      margin-left: 12px;
    }
  }

  &-map {
    flex: 1;
    overflow: hidden;
    background-color: #d8d8d8;

    &__container {
      height: calc(100% + 50px);
      overflow: hidden;
    }
  }

  .icon-list {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 40px;
    left: 25px;

    > a {
      margin-right: 16px;
    }
  }
}
