.m-contacts {
  padding-top: 84px;
  background-color: #4f5969;

  &:before {
    content: 'Контакты';
    text-transform: uppercase;
    display: block;
    font-style: normal;
    font-stretch: normal;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1.6px;
    color: #f3f3f3;
    margin-bottom: 20px;
    text-align: center;
  }

  &__phone {
    width: 325px;
    height: 32px;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .m-hibasiscenter {
    width: 325px;
    height: 32px;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 10px 0 15px;
  }

  &__address {
    width: 325px;
    height: 48px;
    opacity: 0.8;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 15px;
  }

  &__accreditation {
    opacity: 0.8;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin: 0 20px 15px;
  }

  &__how {
    display: flex;
    align-items: center;
    height: 19px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #57ab6a;
    margin-bottom: 30px;

    &:hover {
      cursor: pointer;
    }

    &:before {
      content: 'Как проехать';
    }

    &:after {
      content: '';
      display: block;
      width: 13px;
      height: 13px;
      background-size: contain;
      background-image: url("images/arrow.svg");
      margin-left: 12px;
    }
  }

  &-map {
    overflow: hidden;
    width: 100%;
    height: 459px;
    background-color: #d8d8d8;

    &__container {
      height: calc(100% + 50px);
      overflow: hidden;
    }
  }

  .icon-list {
    display: flex;
    flex-direction: row;

    > a {
      margin-right: 16px;
    }
    padding: 0  0 30px;
  }
}
