@font-face {
    font-family: MuseoSansCyrl;
    src: url("fonts/MuseoSansCyrl.otf") format("opentype");
}

@font-face {
    font-family: "Basis Pro Black";
    src: url("fonts/Basis Grotesque Pro Black.otf") format("opentype");
}

@font-face {
    font-family: "Basis Pro Black";
    src: url("fonts/Basis Grotesque Pro Bold.otf") format("opentype");
}

@font-face {
    font-family: "Basis Pro Black";
    src: url("fonts/Basis Grotesque Pro Light.otf") format("opentype");
}

@font-face {
    font-family: "Basis Pro Black";
    src: url("fonts/Basis Grotesque Pro Medium.otf") format("opentype");
}

@font-face {
    font-family: "Basis Regular";
    src: url("fonts/Basis Grotesque Pro Regular.otf") format("opentype");
}

body {
    margin: 0;
    padding: 0;
    font-family: "Basis Pro Black", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
