.contacts {
  display: flex;
  height: 100%;

  &-info {
    flex: 1;
  }

  &-data {
    display: flex;
    flex-direction: column;
    padding-bottom: 63px;
    height: 100%;
  }

  &-map {
    flex: 1;
    overflow: hidden;

    &__container {
      height: calc(100% + 50px);
      overflow: hidden;
    }
  }

  .contacts-phone {
    width: 430px;
    height: 32px;
    font-size: 35px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.91;
    letter-spacing: normal;
    color: #ffffff;
    margin: 139px 0 0 80px;
  }

  &-gap {
    flex: 1;
  }

  &-email {
    margin: 25px 0 0 80px;
    width: 430px;
    height: 32px;
    font-size: 35px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.91;
    letter-spacing: normal;
    color: #ffffff;
  }

  &-address {
    margin: 25px 0 0 80px;
    width: 430px;
    height: 60px;
    opacity: 0.8;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
  }

  &-accreditation {
    margin: 0 0 0 80px;
    width: 430px;
    opacity: 0.8;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
  }

  &-how {
    height: 30px;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #57ab6a;
    display: flex;
    margin-left: 80px;
    margin-top: 25px;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &:before {
      content: 'Как проехать';
    }

    &:after {
      content: '';
      display: block;
      width: 18px;
      height: 17px;
      object-fit: contain;
      background-image: url("images/arrow.svg");
      margin-left: 12px;
    }
  }


  .icon-list {
    display: flex;
    flex-direction: row;
    padding-left: 80px;

    > a {
      margin-right: 16px;
    }
  }
}

