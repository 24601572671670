.m-vacancies {

  padding: 84px 8px 60px;
  background-color: #394250;

  &__text {

    &:before, &:after {
      display: block;
      font-style: normal;
      font-stretch: normal;
    }

    &:before {
      content: 'Вакансии';
      text-transform: uppercase;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 1.6px;
      color: #f3f3f3;
      margin-bottom: 15px;
      text-align: center;
    }

   .-content {
      max-width: 325px;
      min-height: 120px;
      opacity: 0.8;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }

  .m-hrorbitacenter {
    width: 124px;
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #57ab6a;
    margin: 15px 0 50px;
    display: block;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  a {
    color: rgba(47, 47, 47, 0.8);
    text-decoration: none;
  }

  &__offer {
    margin: 6px;
    width: 156px;
    height: 90px;
    border-radius: 5px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding: 12px 6px 12px 12px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
}