.t-vacancies {
  padding: 40px 25px;
  background-color: #394250;
  position: relative;

  &__id {
    position: absolute;
    top: -75px;
  }

  &:before {
    content: 'Вакансии';
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1.6px;
    color: #f3f3f3;
  }

  &__text {
    width: 50%;
    opacity: 0.8;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 15px;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }

  &-offer {
    margin: 8px;
    width: 164px;
    height: 90px;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    font-size: 16px;
    line-height: 1.25;

    a {
      color: rgba(47, 47, 47, 0.8);
      text-decoration: none;
    }
  }
}

.t-hrorbitacenter {
  width: 124px;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #57ab6a;
  margin: 15px 0 30px;
  display: block;
}

.m-vacancies-view {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100000;
  overflow-y: auto;
  background-color: #ffffff;
  padding: 99px 25px 25px;

  &__top {
    padding: 25px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.--expanded {
      padding-bottom: 44px;
    }

    &_close {
      background-image: url("../images/close.svg");
      background-repeat: no-repeat;
      margin-left: 16px;
      min-width: 24px;
      min-height: 24px;
      background-size: contain;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__header {
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: #2f2f2f;
    position: absolute;
    z-index: 101;
    top: 74px;
    left: 25px;
    right: 48px;

    &.--fixed {
      position: fixed;
      top: 24px;
    }
  }

  &__description {
    padding-top: 24px;
  }

  &__btn {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ffffff;
    height: 58px;
    background-color: #57ab6a;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    &:before {
      content: 'Написать о себе';
    }

    &:hover {
      cursor: pointer;
    }
  }

  li {
    padding: 8px 0;

    p {
      margin: 0;
    }
  }
}


@media screen and (min-width: 768px) {
  .m-vacancies-view {
    padding: 99px 84px 25px;

    &__header {
      left: 84px;
      right: 107px;
    }
  }
}