
.m-basis {
  padding-top: 154px;

  &__text {
    max-width: 325px;
    min-height: 114px;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #f3f3f3;
    margin-bottom: 16px;

    &:after {
      content: 'Цифровые решения для финансового сектора';
    }
  }

  &__partners {
    margin: 60px 0 26px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.6px;
    text-align: center;
    color: #f3f3f3;

    &:after {
      content: 'Наши партнеры';
    }
  }

  &__partners-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 25px;

    ._partner {
      display: block;
      margin: 7px;
      background-repeat: no-repeat;
      background-size: contain;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.p9 {
        background-image: url("../_desktop/slides/basis/images/interactive/9.svg");
        height: 24px;
        width: 68px;
      }

      &.p2 {
        background-image: url("../_desktop/slides/basis/images/interactive/2.svg");
        height: 26px;
        width: 17px;
      }

      &.p3 {
        background-image: url("../_desktop/slides/basis/images/interactive/3.svg");
        height: 28px;
        width: 28px;
      }

      &.p4 {
        background-image: url("../_desktop/slides/basis/images/interactive/4.svg");
        height: 23px;
        width: 25px;
      }

      &.p5 {
        background-image: url("../_desktop/slides/basis/images/interactive/5.svg");
        height: 16px;
        width: 47px;
      }

      &.p6 {
        background-image: url("../_desktop/slides/basis/images/interactive/6.svg");
        height: 26px;
        width: 26px;
      }

      &.p7 {
        background-image: url("../_desktop/slides/basis/images/interactive/7.svg");
        height: 26px;
        width: 26px;
      }

      &.p8 {
        background-image: url("../_desktop/slides/basis/images/interactive/8.svg");
        height: 31px;
        width: 30px;
      }
    }
  }

  &__products {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.6px;
    text-align: center;
    color: #f3f3f3;
    text-transform: uppercase;
    margin: 63px 0 30px;

    &:after {
      content: 'Наши продукты';
    }
  }

  .m-registration {

    &:before {
      background-image: url("images/registration.png");
      background-color: #016eff;
    }

    &__logo {
      background-image: url("../_desktop/slides/basis/images/registration/registration.svg");
      width: 60px;
      height: 60px;
      background-size: contain;
      margin: 20px 0 15px;
    }
  }

  .m-platform {

    &:before {
      background: #df4848 url("images/platform.svg") no-repeat center center;
    }

    &__logo {
      background-image: url("../_desktop/slides/basis/images/platform/platform.svg");
    }
  }

  .m-documents {

    &:before {
      background-image: url("images/documents.png");
      background-color: #aed7ff;
    }

    &__logo {
      background-image: url("../_desktop/slides/basis/images/documents/documents.svg");
    }
  }

  .m-lk {

    &:before {
      background-image: url("images/lk.png");
      background-color: #fd7866;
    }

    &__logo {
      background-image: url("../_desktop/slides/basis/images/lk/lk.svg");
    }
  }

  .m-developer-system {

    &:before {
      background-image: url("images/developer-system.png");
      background-color: #4051b5;
    }

    &__logo {
      background-image: url("../_desktop/slides/basis/images/developer-system/developer-system.svg");
    }

    ul {
      columns: 2;
      list-style-type: none;
      padding-left: 0;

      li {
        position: relative;
        padding: 8px 0 0 25px;
        text-align: left;

        &:before {
          position: absolute;
          left: 0;
          top: 11px;
          content: ' ';
          width: 8px;
          height: 8px;
          border: 4px solid rgba(#ffffff, 0.8);
          overflow: hidden;
        }
      }
    }
  }
}

.m-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 80px;

  &:before {
    content: '';
    display: block;
    width: 100%;
    min-height: 320px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  .--logo {
    width: 60px;
    height: 60px;
    background-size: contain;
    margin: 20px 0 15px;
  }

  .--text {
    padding: 0 8px;

    .-header, .-content {
      display: block;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }

    .-header {
      font-size: 24px;
      font-weight: bold;
      line-height: normal;
      max-width: 320px;
    }

    .-content {
      opacity: 0.8;
      font-size: 16px;
      font-weight: 500;;
      line-height: 1.5;
      margin-top: 15px;
      max-width: 325px;
    }
  }

  .centered-text {
    padding-top: 15px;
  }
}


