//variables
$hHeight: 100%;
$text-block-width: 400px;

$dark-font-color: #212121;
$accent-font-color: #CA9CE1;
$light-font-color: #fff;
$text-overlay-color: #000;
$text-overlay-opacity: 0.6;
$slide-overlay-color: #fff;
$slide-overlay-opacity: 0.6;
$slide-duration: 0.30s;
//transitions and mixins

//transitions mixin
@mixin transition-mix($property: all, $duration: 0.2s, $timing: linear, $delay: 0s) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
  transition-delay: $delay;
}

//position absolute mixin
@mixin position-absolute($top: null, $left: null, $right: null, $bottom: null) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

html, body, #root, .app {
  height: $hHeight;
}

body {
  -webkit-tap-highlight-color: transparent;
  background-color: #4f5969;
}

* {
  box-sizing: border-box;
}

.app {
}

.base {
  display: flex;
  height: $hHeight;

  &__media {
    @include transition-mix($duration: $slide-duration);
    width: 45%;
    overflow: hidden;
    display: flex;
    justify-content: center;

    &.mini {
      width: 100px;
    }
  }

  &__slides {
    flex: 1;
  }
}

.flexbox-slider {
  display: flex;
  width: 100%;
  height: $hHeight;

  .flexbox-slide {
    @include transition-mix($duration: $slide-duration);
    padding: 0;
    width: 68px;
    min-width: 68px;
    height: 100%;
    position: relative;

    overflow: hidden;

    &:not(.active) {
      &:hover {
        cursor: pointer;
      }
    }

  }

  .flexbox-slide.active {
    width: 100%;

    .header {
      transform: rotate(0);
    }

    .header-basis {
      transform: rotate(0) translate3d(60px, 40px, 0);
    }

    .header-mission {
      transform: rotate(0) translate3d(40px, 40px, 0);
    }

    .header-vacancies {
      transform: rotate(0) translate3d(40px, 40px, 0);
    }

    .header-contacts {
      transform: rotate(0) translate3d(40px, 40px, 0);
    }
  }

  .slide {

    &-basis {
      background-color: #1f2733;
      z-index: 10;
      overflow: inherit;

      &:not(.active) {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: -100px;
          right: 0;
          bottom: 0;
          z-index: 100;
          background-color: transparent;
        }

        .basis-content {
          transition: padding-left $slide-duration;
          padding-left: 40px;
        }
      }

      &:before {
        content: '';
        z-index: 5;
        top: 0;
        left: 0;
        right: 0;
        height: 100px;
        background-color: inherit;
        display: block;
        position: absolute;
      }
    }

    &-contacts {
      //z-index: 40;
      background-color: #4f5969;
    }

    &-vacancies {
      //z-index: 30;
      background-color: #394250;
    }

    &-mission {
      z-index: 20;
      background-color: #262e3c;
    }

  }
}

.header {
  @include transition-mix($duration: $slide-duration);
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #f3f3f3;

  &.header-basis {
    transform: rotate(90deg) translate3d(85px, 22px, 0);
    background-image: url("./images/logo.svg");
    width: 115px;
    height: 25px;
    background-size: contain;
    position: relative;
    z-index: 10;
  }

  &.header-mission {
    transform: rotate(90deg) translate3d(84px, 18px, 0);

    &:after {
      content: 'Миссия';
    }
  }

  &.header-vacancies {
    transform: rotate(90deg) translate3d(97px, 31px, 0);

    &:after {
      content: 'Вакансии';
    }
  }

  &.header-contacts {
    transform: rotate(90deg) translate3d(97px, 29px, 0);

    &:after {
      content: 'Контакты';
    }
  }
}


.--flex {
  display: flex;
}

.skeleton-screen {

  display: flex;
  flex-direction: column;
  padding: 40px 0;

  .line {
    float: left;
    width: 20%;
    height: 16px;
    border-radius: 7px;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 900px;
    animation: shine-lines 1.6s infinite linear;

    &:nth-child(2) {
      width: 80%;
    }

    &:nth-child(3) {
      width: 90%;
    }

    &:nth-child(4) {
      width: 50%;
    }

    &:nth-child(5) {
      width: 65%;
    }

    &:nth-child(6) {
      width: 60%;
    }

    &:nth-child(7) {
      width: 80%;
    }

    &:nth-child(8) {
      width: 75%;
    }

    &:nth-child(9) {
      width: 70%;
    }

    &:nth-child(10) {
      width: 30%;
    }
  }

  .line + .line {
    margin-top: 16px;
  }
  .line ~ .line {
    background-color: #ddd;
  }

  @keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%, 100% {
      background-position: 600px;
    }
  }

}

.centered-text {
  text-align: center;
}

.link-to-service {
  position: relative;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #57ab6a;
  width: fit-content;
  @media (min-width: 768px) and (max-width: 1279px) {
    color: #fff;
  }

  &::after {
    position: absolute;
    right: -25px;
    top: 2px;
    content: url("./images/right-arrow-green.svg");

    @media (min-width: 768px) and (max-width: 1279px) {
      content: url("./images/right-arrow-white.svg");
    }
  }
}
