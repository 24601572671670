$animationShow: showContent 500ms;
$animationHide: hideContent 500ms;

.left-content {
  width: 100%;
  height: 100%;
  transition: background-color 500ms;
  overflow: hidden;
  display: flex;
  align-items: center;

  flex-wrap: nowrap;

  &.--interactive {
    background-color: #469658;

    .--interactive {
      opacity: 1;
      animation: $animationShow;
    }

    div.--content.--documents {
     margin-left: -500%;
    }
  }

  &.--lk {
    background-color: #fd7866;

    .--lk {
      opacity: 1;
      animation: $animationShow;
    }

    div.--content.--documents {
      margin-left: -400%;
    }
  }

  &.--developer-system {
    background-color: #4051b5;

    .--developer-system {
      opacity: 1;
      animation: $animationShow;
    }

    div.--content.--documents {
      margin-left: -300%;
    }
  }

  &.--registration {
    background-color: #016eff;

    .--registration {
      opacity: 1;
      animation: $animationShow;
    }

    div.--content.--documents {
      margin-left: -200%;
    }
  }

  &.--platform {
    background-color: #df4848;

    .--platform {
      opacity: 1;
      animation: $animationShow;
    }

    div.--content.--documents {
      margin-left: -100%;
    }
  }

  &.--documents {
    background-color: #aed7ff;

    .--documents {
      opacity: 1;
      animation: $animationShow;
    }

    div.--content.--documents {
      margin-left: 0%;
    }
  }

  div.--content {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    min-width: 100%;
    transition: margin-left 500ms;
    opacity: 0;
    animation: $animationHide;

    &.--interactive {
      display: flex;
      align-items: center;
      justify-content: center;

      .wrapper-square {
        display: grid;
        grid-template-columns: repeat(7, 40px);
        grid-template-rows: repeat(9, 40px);
        grid-gap: 1.5rem;

        .square {
          background-color: #a2d452;
        }
      }
    }

    &.--documents {
      background-image: url("blocks/images/documents@2x.png");
      background-position: center left;
    }

    &.--lk {
      background-image: url("blocks/images/lk@2x.png");
      background-position: center center;
    }

    &.--platform {
      background-image: url("blocks/images/platform@2x.png");
    }

    &.--registration {
      background-image: url("blocks/images/registration@2x.png");
    }

    &.--developer-system {
      background-image: url("blocks/images/developer-system@2x.png");
    }
  }

}

@keyframes showContent {
  0% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hideContent {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
