
.mission {
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: -20px;
  bottom: 0;
  margin: 100px 0 0 70px;
  z-index: 1;
  padding-left: 70px;
  padding-top: 20px;
}

.mission-text {
  width: 453px;
  height: 380px;
  opacity: 0.8;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  position: relative;

  &:before, &:after {
    position: absolute;
    opacity: 0.15;
    font-family: 'Basis Regular', serif;;
    font-size: 220px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8e8295;
  }

  &:before {
    content: '“';
    top: -65px;
    left: -85px;
  }
}

.mission-sign {
  margin: 47px 0 0 60px;
  width: 378px;
  height: 32px;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  display: flex;
  align-items: center;

  &:before {
    content: 'Дарья Кучина, CEO';
    display: inline-block;
  }
}


.mission-ceo {
  position: absolute;
  bottom: 0;
  top: 50px;
  right: 0;
  left: 575px;
  background-image: url("images/ceo@2x.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  min-width: 820px;
  min-height: 700px;
}

// @media screen and (min-width: 1300px) {
//   .mission-ceo {
//     min-width: 500px;
//   }
// }

// @media screen and (min-width: 1400px) {
//   .mission-ceo {
//     min-width: 550px;
//   }
// }

// @media screen and (min-width: 1500px) {
//   .mission-ceo {
//     min-width: 600px;
//   }
// }

// @media screen and (min-width: 1600px) {
//   .mission-ceo {
//     min-width: 650px;
//   }
// }

// @media screen and (min-width: 1700px) {
//   .mission-ceo {
//     min-width: 700px;
//   }
// }

// @media screen and (min-width: 1800px) {
//   .mission-ceo {
//     min-width: 750px;
//   }
// }

// @media screen and (min-width: 1900px) {
//   .mission-ceo {
//     min-width: 800px;
//   }
// }

// @media screen and (min-width: 2000px) {
//   .mission-ceo {
//     min-width: 850px;
//   }
// }

// @media screen and (min-width: 2100px) {
//   .mission-ceo {
//     min-width: 900px;
//   }
// }
