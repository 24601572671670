.t-basis {
  font-style: normal;
  font-stretch: normal;

  background-color: #1f2733;

  &-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 25px 20px;
    display: flex;
    justify-content: space-between;
    background-color: #1f2733;
  }

  &-logo {
    background-image: url("../images/logo.svg");
    height: 25px;
    width: 115px;
  }

  &-menu {
    display: flex;
    opacity: 0.8;
    font-size: 16px;

    a {
      color: #ffffff;


      &:hover {
        cursor: pointer;
      }
    }

    a + a {
      margin-left: 30px;
    }
  }

  &-welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 75px;
    padding-bottom: 60px;
    align-items: center;
    height: 100%;
    position: relative;

    &__text {
      width: 610px;
      display: block;
      color: #f3f3f3;
      font-size: 46px;
      font-weight: bold;
      text-align: center;
    }

    a[href="#products"] {
      position: absolute;
      bottom: 60px;
    }
  }

  &__partners {
    margin: 60px 0 26px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1.6px;
    text-align: center;
    color: #f3f3f3;

    &:after {
      content: 'Наши партнеры';
    }
  }

  &__partners-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 25px;

    ._partner {
      display: block;
      margin: 7px;
      background-repeat: no-repeat;
      background-size: contain;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.p9 {
        background-image: url("../_desktop/slides/basis/images/interactive/9.svg");
        height: 24px;
        width: 68px;
      }

      &.p2 {
        background-image: url("../_desktop/slides/basis/images/interactive/2.svg");
        height: 26px;
        width: 17px;
      }

      &.p3 {
        background-image: url("../_desktop/slides/basis/images/interactive/3.svg");
        height: 28px;
        width: 28px;
      }

      &.p4 {
        background-image: url("../_desktop/slides/basis/images/interactive/4.svg");
        height: 23px;
        width: 25px;
      }

      &.p5 {
        background-image: url("../_desktop/slides/basis/images/interactive/5.svg");
        height: 16px;
        width: 47px;
      }

      &.p6 {
        background-image: url("../_desktop/slides/basis/images/interactive/6.svg");
        height: 26px;
        width: 26px;
      }

      &.p7 {
        background-image: url("../_desktop/slides/basis/images/interactive/7.svg");
        height: 26px;
        width: 26px;
      }

      &.p8 {
        background-image: url("../_desktop/slides/basis/images/interactive/8.svg");
        height: 31px;
        width: 30px;
      }
    }
  }

  &__products {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: relative;
    animation: 1000ms moveArrow infinite;
    color: #f3f3f3;

    &:hover {
      cursor: pointer;
    }

    &:before {
      content: '';
      background-image: url("../_desktop/slides/basis/images/interactive/products_arrow.svg");
      width: 15px;
      height: 9px;
      display: block;
      margin-right: 12px;
    }

    &:after {
      content: 'Наши продукты';
    }
  }
}

.t-product {
  position: relative;
  display: flex;
  padding: 20px 0;

  &__id {
    position: absolute;
    top: -75px;
  }

  &__image {
    flex: 1;
    height: 480px;
  }

  &__text {
    flex: 1;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    max-width: 500px;

    &_header {
      font-size: 24px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      margin: 15px 0;
    }

    &_content {
      opacity: 0.8;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: normal;
    }
  }
}

.t-lk {
  background: #fd7866;

  &__image {
    background: url("./images/lk.png") no-repeat center center;
    background-size: contain;
  }
}

.t-developer-system {
  background: #4051b5;

  &__image {
    background: url("./images/developer-system.png") no-repeat center center;
    background-size: contain;
  }

  ul {
    columns: 2;
    list-style-type: none;
    padding-left: 0;

    li {
      position: relative;
      padding: 8px 0 0 25px;
      text-align: left;

      &:before {
        position: absolute;
        left: 0;
        top: 11px;
        content: ' ';
        width: 8px;
        height: 8px;
        border: 4px solid rgba(#ffffff, 0.8);
        overflow: hidden;
      }
    }
  }
}

.t-registration {
  background: #016eff;

  &__image {
    background: url("./images/registration.png") no-repeat center center;
    background-size: contain;
  }
}

.t-platform {
  background: #df4848;

  &__image {
    background: url("./images/platform.png") no-repeat center center;
    background-size: contain;
  }
}

.t-documents {
  background: #aed7ff;

  &__image {
    background: url("./images/documents.png") no-repeat center center;
    background-size: contain;
  }

  .t-product__text {
    color: #2f2f2f;
  }
}
