.basis {
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: -20px;
  bottom: 0;
  margin: 0 0 0 60px;
  padding-top: 100px;
  z-index: 1;
}

.basis-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-style: normal;
  font-stretch: normal;
  padding-right: 45px;
  min-height: 100%;
  height: fit-content;
  color: #ffffff;

  ._logo {
    min-height: 80px;
    width: 80px;
    height: 80px;
  }

  ._header {
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    margin: 25px 0 18px;
  }

  ._text {
    opacity: 0.8;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
  }

  &.--interactive {
    padding-bottom: 30px;

    ._header {
      width: 465px;
      min-height: 165px;
      font-size: 46px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: #f3f3f3;

      &:after {
        content: 'Цифровые решения для финансового сектора';
      }
    }

    ._partners {
      height: 30px;
      opacity: 0.8;
      font-size: 14px;
      font-weight: 500;
      line-height: 2.14;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      margin: 30px 0 23px;
      width: 200px;

      &:after {
        content: 'Наши партнеры';
      }
    }

    ._partners-list {
      max-width: 462px;
      height: 40px;
      background-image: url("images/interactive/partners.svg");
      background-repeat: no-repeat;
      background-size: contain;
      min-width: 300px;
    }

    ._products {
      margin-top: 90px;
      opacity: 0.8;
      font-size: 14px;
      font-weight: 500;
      line-height: 2.14;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      position: relative;
      animation: 1000ms moveArrow infinite;
      min-width: 300px;

      &:hover {
        cursor: pointer;
      }

      &:before {
        content: '';
        background-image: url("images/interactive/products_arrow.svg");
        width: 15px;
        height: 9px;
        display: block;
        margin-right: 12px;
      }

      &:after {
        content: 'Наши продукты';
      }
    }
  }

  &.--documents {
    padding-bottom: 177px;

    ._logo {
      background-image: url("images/documents/documents.svg");
    }

    ._header {
      width: 465px;
      min-height: 96px;
    }

    ._text {
      width: 465px;
      min-height: 50px;
    }

  }

  &.--lk {
    padding-bottom: 162px;

    ._logo {
      background-image: url("images/lk/lk.svg");
    }

    ._header {
      width: 430px;
      min-height: 96px;
    }

    ._text {
      width: 430px;
      min-height: 180px;
    }
  }

  &.--developer-system {
    padding-bottom: 162px;

    ._logo {
      background-image: url("images/developer-system/developer-system.svg");
    }

    ._header {
      width: 430px;
      min-height: 96px;
    }

    ._text {
      width: 430px;
      min-height: 180px;

      ul {
        columns: 2;
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          position: relative;
          padding: 8px 0 0 25px;
          text-align: left;

          &:before {
            position: absolute;
            left: 0;
            top: 15px;
            content: ' ';
            width: 8px;
            height: 8px;
            border: 4px solid rgba(#ffffff, 0.8);
            overflow: hidden;
          }
        }
      }
    }
  }

  &.--platform {
    padding-bottom: 162px;

    ._logo {
      background-image: url("images/platform/platform.svg");
    }

    ._header {
      width: 465px;
      min-height: 96px;
    }

    ._text {
      width: 465px;
      min-height: 210px;
    }
  }

  &.--registration {
    padding-bottom: 162px;

    ._logo {
      background-image: url("images/registration/registration.svg");
    }

    ._header {
      width: 430px;
      min-height: 96px;
    }

    ._text {
      width: 477px;
      min-height: 120px;
    }
  }
}

@keyframes moveArrow {
  0% {
    top: 0;
  }
  50% {
    top: 5px;
  }
  100% {
    top: 0;
  }
}

@media screen and (max-height: 650px) {
  .basis-content {
    &.--interactive {
      justify-content: center;
    }
  }
}

@media screen and (max-height: 610px) {
  .basis-content {
    &.--interactive {
      justify-content: flex-start;
    }
  }
}
