
.vacancies {
  margin: 115px 0 0 100px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: -20px;
  bottom: 0;
  padding-bottom: 40px;

  &-text {
    width: 640px;
    height: 90px;
    opacity: 0.8;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff;
  }

  .hrorbitacenter {
    width: 155px;
    height: 30px;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #57ab6a;
    margin: 18px 0 50px;
    display: block;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
    padding-right: 90px;
  }

  a {
    color: rgba(47, 47, 47, 0.8);
    text-decoration: none;
  }

  &-offer {
    margin: 12px;
    width: 200px;
    height: 112px;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: normal;

    &:hover {
      cursor: pointer;
    }
  }
}

.vacancies-view {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(#1f2733, 0.6);
  overflow-y: auto;
  padding: 60px 0;

  &__container {
    width: 733px;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 40px;
    margin: auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header_text {
    font-size: 40px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: #2f2f2f;
  }

  &__close {
    background-image: url("../../../images/close.svg");
    background-repeat: no-repeat;
    margin-left: 16px;
    margin-top: 16px;
    min-width: 24px;
    min-height: 24px;
    background-size: contain;
    align-self: flex-start;

    &:hover {
      cursor: pointer;
    }
  }

  &__description {

  }

  &__btn {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: normal;
    color: #ffffff;
    height: 58px;
    background-color: #57ab6a;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    &:before {
      content: 'Написать о себе';
    }

    &:hover {
      cursor: pointer;
    }
  }

  li {
    padding: 8px 0;

    p {
      margin: 0;
    }
  }
}